@use '@/styles/utils' as *;

.container {
    position: relative;
    z-index: 1;

    &::before {
        background-image: url('/assets/images/line-bg.png');
        background-position: center center;
        background-repeat: repeat;
        background-size: auto;
        content: '';
        height: 100%;
        left: 0;
        opacity: 0.03;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: -1;
    }
}

.wrapper {
    flex: 1;
    margin-inline: auto;
    max-width: 108rem;
    padding-block: 8rem 20rem;
    padding-inline: 6rem;
    position: relative;

    @media (width <= $container-width-min) {
        max-width: 100vw;
        padding-block: 8rem 2rem;
        padding-inline: 2rem;
        word-wrap: break-word;
    }
}

.title {
    border-bottom: 1px solid $color-base;
    margin-block-end: 2rem;
    padding-block-end: 3rem;

    @media (width <= 500px) {
        font-size: 3.4rem;
        word-wrap: break-word;
    }
}

.description {
    margin-block-end: 3rem;
}
