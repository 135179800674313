@use '@/styles/utils.scss' as *;

.carousel {
    margin-block: 4rem;

    .thumbnail {
        aspect-ratio: 16 / 9;
        max-height: 15rem;
        max-width: 100%;
        object-fit: cover;
        width: 100%;
    }

    img {
        aspect-ratio: 16 / 9;
        height: 100%;
        max-height: 50rem;
        object-fit: cover;
        width: 100%;
    }
}
